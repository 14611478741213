<template>
  <div>
    <stripe />
  </div>
  <!-- <TfCard>
    <template v-slot:title-text>
      <span>Payment</span>
    </template>
    <template v-slot:title-icon>
      <img src="@/assets/info.svg" alt="help" />
    </template>
    <template v-slot:footer>
      <stripe />
      <book-a-hotel-footer link="null" />
    </template>
  </TfCard> -->
</template>

<script>
// import BookAHotelFooter from '../BookAHotelFooter.vue';
// import Stripe from '../../../views/Stripe.vue';
import Stripe from '@/components/Stripe.vue';

export default {
  components: {
    // BookAHotelFooter,
    Stripe,
  },
};
</script>

<style>
</style>
