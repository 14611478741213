<template>
  <div class="card-container" :class="{'card-selected' : isSelected}">
    <div class="card-checkbox" :class="{'card-checkbox-selected': isSelected}"></div>
    <span>{{label}}</span>
  </div>
</template>

<script>
export default {
  name: 'CardCheckbox',
  props: ['label', 'isSelected'],
};
</script>

<style lang="scss" scoped>
.card-container {
  background: white;
  border: 2px solid #dcdee6;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
  color: #a1a3a7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.card-selected {
  background: #fef1eb;
  border: 2px solid #FF4500;
  color: #FF4500;
}
.card-checkbox {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #dcdee6;
}
.card-checkbox-selected {
  border: 6px solid #FF4500;
}
</style>
