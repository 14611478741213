<template>
  <v-layout row
            class="payment-integration">
    <v-card min-width="470">
      <v-card-title class="payment-header">
        <span><b>Payment</b></span>
      </v-card-title>
      <v-flex class="border-payment"></v-flex>
      <v-form class="pa-6"
              @submit.prevent="payment">
        <v-flex class="field">
          <!-- <span>How would you like to pay?</span>
          <div class="check-box-container">
            <div class="check-box"
                 @click="cardCheckboxClick(paymentType)"
                 v-for="paymentType in paymentTypes"
                 :key="paymentType">
              <CardCheckbox :label="paymentType"
                            :isSelected="paymentType === selectedPaymentType" />
            </div>
          </div> -->
        </v-flex>
        <div v-if="!payAtHotel">
          <v-flex d-flex>
            <div lg6
                 xl6
                 md12
                 sm12
                 xs12>
              <h3>Total Hotel Cost</h3>
            </div>
            <div lg6
                 xl6
                 md12
                 sm12
                 xs12
                 style="margin-left:auto">
              <h3>{{getCurrencyData.shortName}}{{ Math.ceil(bookingAmount * getCurrencyData.exchangeRate) }}</h3>
            </div>
          </v-flex>
          <h4 class="info-text">
            A 2% Credit Card Surcharge will be added to the cost of this Hotel.
          </h4>
        </div>
        <div v-if="payAtHotel">
          <v-flex d-flex>
            <div lg6
                 xl6
                 md12
                 sm12
                 xs12>
              <h3>Total Hotel Cost</h3>
            </div>
            <div lg6
                 xl6
                 md12
                 sm12
                 xs12
                 style="margin-left:auto">
              <h3>{{getCurrencyData.shortName}}{{ Math.ceil(bookingAmount * getCurrencyData.exchangeRate) }}</h3>
            </div>
          </v-flex>
          <div class="info-text">
            <v-icon color="yellow"
                    size="18">mdi-alert-circle</v-icon>
            Your card won't be charged, we only need your card details to guarantee your booking.
          </div>
        </div>
        <v-flex>
          <div class="payment-card-container"
               ref="cardNumber"
               @onChange="emitCardDetails"></div>
        </v-flex>
        <!-- <v-flex xs12 class="checkbox-controls pt-3">
            <v-checkbox
              v-model="isChecked"
              label="I agree to terms and conditions"
              color="#ff4500"
              hide-details
          ></v-checkbox>
        </v-flex> -->
        <v-flex class="border-payment"></v-flex>
        <v-layout class="pa-6">
          <v-flex xs12>
            <Button :loading="loadingInProgress"
                    type="submit"
                    value="Continue"
                    color="primary"
                    height="40px"
                    width="100%" />
          </v-flex>
        </v-layout>
      </v-form>
      <!--<v-snackbar
      v-model="snackbar"
    >
      {{ bookingError }}
      <v-btn
        color="red"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>-->
      <div v-if="openDialog">
        <v-dialog v-model="openDialog"
                  max-width="370px">
          <Dialog :dialogData="bookingError"
                  :onCloseDialog="closeDialog"
                  mode="snackbar-error" />
        </v-dialog>
      </div>
    </v-card>
  </v-layout>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';
import { HTTP_STATUS_CODE, TOASTER_MESSAGE } from '@/constants';
import Button from '@/common/Button.vue';
import CardCheckbox from '@/common/CardCheckbox.vue';
import Dialog from '@/common/ErrorDialog.vue';

const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
const elements = stripe.elements();
const {
  createStripeIntent,
  updateUserDetailService,
} = require('@/services/user/user.service');

let card;

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      snackbar: false,
      openDialog: false,
      isChecked: false,
      paymentTypes: ['Pay now', 'Pay at hotel'],
      selectedPaymentType: 'Pay now',
      payAtHotel: false,
      userDetails: {},
      loadingInProgress: false,
    };
  },
  components: {
    Button,
    CardCheckbox,
    Dialog,
  },
  mounted() {
    if (card !== undefined) {
      card.mount(this.$refs.cardNumber);
      return;
    }
    card = elements.create('card', {
      hidePostalCode: true,
      iconStyle: 'solid',
      style: {
        border: '1px solid black',
        base: {
          iconColor: '#E7E7E9',
          color: '#FF4500',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          ':-webkit-autofill': {
            color: 'red',
          },
          '::placeholder': {
            color: 'grey',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      },
    });
    card.mount(this.$refs.cardNumber);
  },
  computed: {
    ...mapState({
      bookingError: state => state.bookings.bookingError,
    }),
    ...mapGetters({
      bookingAmount: 'bookingAmount',
      booking: 'booking',
      guests: 'guests',
      selectedUserType: 'selectedUserType',
      couponCode: 'couponCode',
      getCurrencyData: 'getCurrencyData',
    }),
    disabled() {
      if (this.isChecked) {
        return false;
      }
      return true;
    },
  },
  watch: {
    bookingError() {
      if (this.bookingError !== null) {
        this.snackbar = true;
        this.openDialog = true;
      }
      // setTimeout(() => this.resetError(), SNACKBAR_TIMEOUT);
    },
  },
  methods: {
    ...mapActions(['bookHotel', 'resetBookingError', 'setBookingError']),
    resetError() {
      this.snackbar = false;
      this.resetBookingError();
    },
    closeDialog() {
      this.snackbar = false;
      this.openDialog = false;
      this.resetBookingError();
    },
    getFirstName(name) {
      this.firstName = name;
    },
    getLastName(name) {
      this.lastName = name;
    },
    emitCardDetails() { }, // add cardDetails as parameter if needed
    async payment() {
      // Below code will seperate the Pay Now & Pay at hotel Options.
      /* if (this.payAtHotel) {
        this.stripeCreateIntent();
      } else {
        try {
          const details = await stripe.createToken(card);
          if (details.error) {
            throw new Error(details.error.message);
          }
          const { token } = details; // eslint-disable-line
          if (token) {
            this.bookRoom(token);
          } else {
            this.setBookingError(TOASTER_MESSAGE.TROUBLE);
          }
        } catch (err) {
          this.setBookingError({
            cardError: true,
            error: err.message,
          });
        }
      } */
      this.stripeCreateIntent();
    },
    async stripeCreateIntent() {
      this.loadingInProgress = true;
      try {
        const createIntentDetails = await createStripeIntent();
        if (createIntentDetails.status === HTTP_STATUS_CODE.SUCCESS) {
          const clientSecret = createIntentDetails.data.secret;
          stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card,
              billing_details: {
                email: this.guests.email,
              },
            },
          }).then(async (result) => {
            if (result) {
              if (result.error) {
                this.setBookingError({
                  cardError: true,
                  error: result.error.message,
                });
                this.loadingInProgress = false;
              } else {
                const bookingDetails = {
                  // eslint-disable-next-line no-underscore-dangle
                  hotelId: this.booking._id,
                  // eslint-disable-next-line no-underscore-dangle
                  roomId: this.booking.selectedRoom._id,
                  // reservationStart: `${moment(this.booking.date).format('YYYY-MM-DD')} ${moment(`${this.booking.date} ${this.booking.time}`).format('HH:mm')}`,
                  reservationStart: `${moment(this.booking.date).format('YYYY-MM-DD')} ${this.booking.timeSlot.checkin}`,
                  reservationEnd: `${moment(this.booking.date).format('YYYY-MM-DD')} ${this.booking.timeSlot.checkout}`,
                  duration: this.getDuration(this.booking.duration),
                  guest: {
                    firstName: this.guests.firstName,
                    lastName: this.guests.lastName,
                    email: this.guests.email,
                    mobile: this.guests.mobile,
                    comment: this.guests.comments,
                    dob: this.guests.dob,
                  },
                  userComment: this.guests.comments,
                  payAtHotel: this.payAtHotel,
                  paymentMethod: result.setupIntent.payment_method,
                  customerID: createIntentDetails.data.custId,
                  timezone: moment.tz.guess(),
                  couponCode: this.couponCode,
                  bookingDevice: this.$cookies.get('device'),
                };
                const userDetails = {
                  firstName: this.guests.firstName,
                  lastName: this.guests.lastName,
                  fullName: `${this.guests.firstName} ${this.guests.lastName}`,
                  mobile: this.guests.mobile,
                  email: this.guests.email,
                  dob: this.guests.dob,
                };
                try {
                  // const saveIntent = await savePaymentIntent(bookingDetails);
                  this.bookHotel(bookingDetails);
                  if (this.guests.selectedUserType === 'Myself') {
                    await updateUserDetailService(userDetails);
                  }
                } catch (err) {
                  this.setBookingError({
                    cardError: true,
                    error: TOASTER_MESSAGE.TROUBLE,
                  });
                  this.loadingInProgress = false;
                }
              }
            }
          });
        } else {
          throw new Error();
        }
      } catch (err) {
        this.setBookingError({
          cardError: true,
          error: TOASTER_MESSAGE.TROUBLE,
        });
      }
    },
    getDuration(duration) {
      switch (duration) {
        case '3h':
          return 3;
        case '6h':
          return 6;
        case '12h':
          return 12;
        default:
          return 3;
      }
    },
    cardCheckboxClick(type) {
      this.selectedPaymentType = type;
      if (type === 'Pay now') {
        this.payAtHotel = false;
      } else {
        this.payAtHotel = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.payment-integration {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .payment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.info-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.info-text {
  margin: 0 0 15px 0px;
  font-size: 12px;
}

.payment-card-container {
  padding: 10px;
  border: 2px solid #E7E7E9;
  border-radius: 4px;
}

.border-payment {
  border: 1px solid #E7E7E9
}

.booking-amount {
  font-size: 24px;
  line-height: 32px;
  color: #080914;
}

.checkbox-controls {
  .v-input--selection-controls {
    padding: unset;
    margin: unset;
  }
}

.label {
  font-size: 16px;
}

.check-box-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4px 0 20px 0;

  .check-box {
    width: 49%;
  }
}

// responsiveness design
@media only screen and (max-width: 768px) {
  .payment-integration {
    .v-card {
      min-width: 100% !important;
    }
  }
}
</style>
